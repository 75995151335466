import { AccountCircle, Alarm, Business, CellTower, Computer, Dashboard, Filter, LeakAdd, LocationOn, Message, NotificationAdd, SettingsEthernet } from "@mui/icons-material";
import React, { ReactNode } from "react";
import { BiAlignLeft } from "react-icons/bi";
import { RoutePermittedRole } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] = [
  /*
  {
    id: "customer",
    title: "Customer",
    messageId: "sidebar.customer",
    permittedRole: RoutePermittedRole.User,
    type: "group",
    children: [
      {
        id: "customerdashboard",
        title: "Dashboard",
        messageId: "sidebar.customer.dashboard",
        type: "item",
        icon: <Dashboard fontSize="inherit" />,
        url: "/dashboard",
      },
  ]},*/
  {
    id: "admin",
    title: "Administrator",
    messageId: "sidebar.adminstration",
    permittedRole: RoutePermittedRole.Admin,
    type: "group",
    children: [
      {
        id: "admindashboard",
        title: "Dashboard",
        messageId: "sidebar.admin.dashboard",
        type: "item",
        icon: <Dashboard fontSize="inherit" />,
        url: "/dashboard",
      },
      {
        id: "adminaccounts",
        title: "Account List",
        messageId: "sidebar.admin.account.accountlist",
        type: "item",
        icon: <AccountCircle fontSize="inherit" />,
        url: "/account/list",
      },
        {
          id: "admincompanys",
          title: "Company List",
          messageId: "sidebar.admin.company.companylist",
          type: "item",
          icon: <Business fontSize="inherit" />,
          url: "/company/list",
        },
        {
          id: "admincompanylocations",
          title: "Locations",
          messageId: "sidebar.admin.company.locations",
          type: "item",
          icon: <LocationOn fontSize="inherit" />,
          url: "/location/list",
      },{
        id: "adminsystem",
        title: "System",
        messageId: "sidebar.admin.system.system",
        type: "group",
        children: [
          {
            id: "adminsystems",
            title: "Systems List",
            messageId: "sidebar.admin.system.systemList",
            type: "item",
            icon: <Computer fontSize="inherit" />,
            url: "/system/list",
          },
          {
            id: "adminmessages",
            title: "Message View",
            messageId: "sidebar.admin.system.messageView",
            type: "item",
            icon: <Message fontSize="inherit" />,
            url: "/message/view",
          },
          {
            id: "adminrepeaters",
            title: "Repeater List",
            messageId: "sidebar.admin.system.repeaterList",
            type: "item",
            icon: <LeakAdd fontSize="inherit" />,
            url: "/repeater/list",
          },
          {
            id: "adminreporters",
            title: "Reporter List",
            messageId: "sidebar.admin.system.reporterList",
            type: "item",
            icon: <CellTower fontSize="inherit" />,
            url: "/reporter/list",
          },
          {
            id: "adminconnectors",
            title: "Connectors List",
            messageId: "sidebar.admin.system.connectorsList",
            type: "item",
            icon: <SettingsEthernet fontSize="inherit" />,
            url: "/connector/list",
          },
          {
            id: "adminnotifications",
            title: "Notifications List",
            messageId: "sidebar.admin.system.notificationsList",
            type: "item",
            icon: <NotificationAdd fontSize="inherit" />,
            url: "/notification/list",
          },
          {
            id: "adminsystemmodels",
            title: "Models",
            messageId: "sidebar.admin.system.models",
            type: "group",
            children: [
              {
                id: "adminsystemmodel",
                title: "SystemModel",
                messageId: "sidebar.admin.system.systemmodel",
                type: "item",
                icon: <Filter fontSize="inherit" />,
                url: "/systemmodel/list",
              },
              {
                id: "adminrepeatermodel",
                title: "SystemModel",
                messageId: "sidebar.admin.system.repeatermodel",
                type: "item",
                icon: <Filter fontSize="inherit" />,
                url: "/repeatermodel/list",
              },
              {
                id: "adminreportermodel",
                title: "SystemModel",
                messageId: "sidebar.admin.system.reportermodel",
                type: "item",
                icon: <Filter fontSize="inherit" />,
                url: "/reportermodel/list",
              }
            ]
          }
        ],
      },
    ],
  },
];
export default routesConfig;
