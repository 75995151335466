import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const RepeaterModelList = React.lazy(() => import('./RepeaterModelList'));
const RepeaterModelCreate = React.lazy(() => import('./RepeaterModelCreate'));



export const repeatermodelsConfig = [
 {
    auth: ['admin'],
        path: '/repeatermodel/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeaterModelList />
  },
  {
    auth: ['admin'],
        path: '/repeatermodel/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeaterModelCreate />
  },
];
