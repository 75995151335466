import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";


const AdminDashboard = React.lazy(() => import("./AdminDashboard"));
const DashboardRedirect = React.lazy(() => import("./DashboardRedirect"));

export const dashboardConfigs = [
  {
    path: "/home",
    element: <DashboardRedirect />,
  },
  {
    path: "/dashboard",
    permittedRole: RoutePermittedRole.Admin, 
    element: <AdminDashboard />,
  }
];
