import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

const Page1 = React.lazy(() => import("./Page1"));
const Page2 = React.lazy(() => import("./Page2"));
export const samplePagesConfigs = [
  {
    path: "/sample/page-1",
    permittedRole: RoutePermittedRole.Admin, 
    element: <Page1 />,
  },
  {
    path: "/sample/page-2",
    permittedRole: RoutePermittedRole.Admin, 
    element: <Page2 />,
  },
];
