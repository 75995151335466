import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';


const MessagesList = React.lazy(() => import('./MessageList'));
const MessageView = React.lazy(() => import('./MessageView'));



export const messagesConfig = [
  {
     auth: ['admin'],
         path: '/message/list',
         permittedRole: RoutePermittedRole.Admin, 
    element: <MessagesList />
   },
   {
      auth: ['admin'],
          path: '/message/list/system/:bySystem',
          permittedRole: RoutePermittedRole.Admin, 
    element: <MessagesList />
    },
    {
       auth: ['admin'],
           path: '/message/list/reporter/:byReporter',
           permittedRole: RoutePermittedRole.Admin, 
    element: <MessagesList />
     },,
    {
       auth: ['admin'],
           path: '/message/list/repeater/:byRepeater',
           permittedRole: RoutePermittedRole.Admin, 
    element: <MessagesList />
     },
 {
    auth: ['admin'],
        path: '/message/view',
        permittedRole: RoutePermittedRole.Admin, 
    element: <MessageView />
  }
];
