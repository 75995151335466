import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const ConnectorsList = React.lazy(() => import('./ConnectorList'));
const ConnectorCreate = React.lazy(() => import('./ConnectorCreate'));



export const connectorsConfig = [
  {
     auth: ['admin'],
         path: '/connector/list',
         permittedRole: RoutePermittedRole.Admin, 
    element: <ConnectorsList />
   },
   {
      auth: ['admin'],
          path: '/connector/list/system/:bySystem',
          permittedRole: RoutePermittedRole.Admin, 
    element: <ConnectorsList />
    },
  {
    auth: ['admin'],
        path: '/connector/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <ConnectorCreate />
  },
];
