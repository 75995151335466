import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const ReporterView = React.lazy(() => import('./ReporterView'));
const ReportersList = React.lazy(() => import('./ReporterList'));
const ReporterCreate = React.lazy(() => import('./ReporterCreate'));



export const reportersConfig = [
  {
     auth: ['admin'],
         path: '/reporter/list',
         permittedRole: RoutePermittedRole.Admin, 
    element: <ReportersList />
   },
  {
     auth: ['admin'],
         path: '/reporter/:id',
         permittedRole: RoutePermittedRole.Admin, 
    element: <ReporterView />
   },
   {
      auth: ['admin'],
          path: '/reporter/list/system/:bySystem',
          permittedRole: RoutePermittedRole.Admin, 
    element: <ReportersList />
    },
    {
       auth: ['admin'],
           path: '/reporter/list/repeater/:byRepeater',
           permittedRole: RoutePermittedRole.Admin, 
    element: <ReportersList />
     },
  {
    auth: ['admin'],
        path: '/reporter/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <ReporterCreate />
  },
];
