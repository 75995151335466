import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const LocationsList = React.lazy(() => import('./LocationList'));
const LocationView = React.lazy(() => import('./LocationView'));
const LocationCreate = React.lazy(() => import('./LocationCreate'));



export const locationsConfig = [
 {
    auth: ['admin'],
        path: '/location/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <LocationsList />
  },
 {
    auth: ['admin'],
        path: '/location/list/company/:byCompany',
        permittedRole: RoutePermittedRole.Admin, 
    element: <LocationsList />
  },
  {
    auth: ['admin'],
        path: '/location/:id',
        permittedRole: RoutePermittedRole.Admin, 
    element: <LocationView />
  },
  {
    auth: ['admin'],
        path: '/location/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <LocationCreate />
  },
];
