import React, { useEffect, useState } from "react";
import AppSidebar from "./AppSidebar";
import AppContentView  from "@crema/core/AppContentView";
import AppThemeSetting from "../../AppThemeSetting";
import AppHeader from "./AppHeader";
import clsx from "clsx";
import Box from "@mui/material/Box";
import UserHeaderWrapper from "./UserHeaderWrapper";
import AppFixedFooter from "./AppFixedFooter";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import { LayoutType } from "../../../../shared/constants/AppEnums";
import UserHeaderContainer from "./UserHeaderContainer";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { RoutePermittedRole } from "shared/constants/AppConst";

const UserHeader = () => {
  const { footer, layoutType, footerType } = useLayoutContext();
  const { user } = useAuthUser();

  const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {

      if(user?.role?.includes(RoutePermittedRole.Admin) ) {
        setIsAdmin(true)
      }
  }, [user])

  return (
    <UserHeaderContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <UserHeaderWrapper
        className={clsx("userHeaderWrapper", {
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
        })}
        isAdmin={isAdmin}
      >
        <AppHeader isAdmin={isAdmin} />
        <Box className="mainContent">
          {isAdmin ? <AppSidebar /> : <></>}
          <AppContentView />
          <AppFixedFooter />
        </Box>
      </UserHeaderWrapper>
    </UserHeaderContainer>
  );
};

export default UserHeader;
