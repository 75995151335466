import React, { ReactNode, useEffect, useState } from "react";
import AppLoader from "@crema/core/AppLoader";
import PropTypes from "prop-types";
import { useAuthUser } from "./AuthHooks";
import { useDispatch, useSelector } from "react-redux";
import { Company } from "services/api/Company";
import appStore from "store/app.store";
import { AppDispatch, RootState } from "store/store";
import sessionStore from "store/session.store";
interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {


  const { isLoading, user } = useAuthUser();
  const [load, setLoad] = useState(true);
  const storeDispatch: AppDispatch = useDispatch();
  const storeCompany = useSelector((state: RootState) => state.session.companyObj?.id);

  useEffect(() => {
      if(!storeCompany) {
        _loadData();
      }
  },[user?.company])


  const _loadData = () => {
    if(user?.company) {
      Company.companyAndSystems(user.company, true).then(com => {
          if(com) {
            storeDispatch(sessionStore.actions.setCompanyObj(com));
            setLoad(false)
          }else{
            setLoad(false)
          }
      }).catch(e => {
          storeDispatch(appStore.actions.setError('fam.dashboard.loadCustomerCompany.failed'));
      });
    }else{
      setLoad(false)
    }

  }

  return isLoading || load ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
