import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const ReporterModelList = React.lazy(() => import('./ReporterModelList'));
const ReporterModelCreate = React.lazy(() => import('./ReporterModelCreate'));



export const reportermodelsConfig = [
 {
    auth: ['admin'],
        path: '/reportermodel/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <ReporterModelList />
  },
  {
    auth: ['admin'],
        path: '/reportermodel/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <ReporterModelCreate />
  },
];
