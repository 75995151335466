import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';


const NotificationsList = React.lazy(() => import('./NotificationList'));
const NotificationCreate = React.lazy(() => import('./NotificationCreate'));



export const notificationsConfig = [
  {
     auth: ['admin'],
         path: '/notification/list',
         permittedRole: RoutePermittedRole.Admin, 
    element: <NotificationsList />
   },
  {
     auth: ['admin'],
         path: '/notification/list/system/:bySystem',
         permittedRole: RoutePermittedRole.Admin, 
    element: <NotificationsList />
   },
  {
     auth: ['admin'],
         path: '/notification/create',
         permittedRole: RoutePermittedRole.Admin, 
    element: <NotificationCreate />
   },
];
