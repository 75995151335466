

import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import appStore from 'store/app.store';
import AppMessageView from '@crema/core/AppMessageView';
import { AppLoader } from '@crema';
import { ToastMsg as Toast } from 'data/models/ToastMsg';
import { useIntl } from 'react-intl';

interface InfoViewProps {}

const ToastMsg: React.FC<InfoViewProps> = () => {

  const storeDispatch: AppDispatch = useDispatch();

  const {messages} = useIntl();
  const {error, loading, message, toast} = useSelector((state: RootState) => state.app);
  const [stateErr, setStateErr] = useState<string>('');
  const [stateMsg, setStateMsg] = useState<string>('');
  const [stateToast, setStateToast] = useState<Toast>();

  useEffect(() => {

    if(error != stateErr) {
      let str = error;
      if(messages[error]) {
        str = messages[error]+''
      }
      setStateErr(str)
    }
    if(message != stateMsg) {
      let str = message;
      if(messages[message]) {
        str = messages[message]+''
      }
      setStateMsg(str)
    }
    if(toast && toast != stateToast) {
      setStateToast(toast)
    }

  }, [error, toast, message])

  const afterShow = () => {
    storeDispatch(appStore.actions.setMessage(''))
  }
  const afterShowToast = () => {
    storeDispatch(appStore.actions.clearToast())
  }
  const afterShowError = () => {
    storeDispatch(appStore.actions.setError(''))
  }
  const showMessage = () => {
    return <AppMessageView variant='success' onClose={afterShow} message={stateMsg.toString()} />;
  };

  const showError = () => {
    return <AppMessageView variant='error'  onClose={afterShowError}  message={stateErr.toString()} />;
  };
  const showToast = () => {
    let str = stateToast?.message;
      if(messages[stateToast?.message]) {
        str = messages[stateToast?.message]+''
      }
    return <AppMessageView variant={stateToast ?  stateToast?.type : 'info'}  onCloses={afterShowToast}  message={str ?? 'no toast message'} />;
  };

  return (
    <>
      {loading && <AppLoader />}

      {stateMsg && showMessage()}
      {stateToast && showToast()}
      {stateErr && showError()}
    </>
  );
};

export default ToastMsg;
