import React from "react";


const CustomerDashboard = React.lazy(() => import("./CustomerDashboard"));
const CustomerMessagesList = React.lazy(() => import("./CustomerMessage/CustomerMessagesList"));
const CustomerNotificationCreate = React.lazy(() => import("./CustomerNotification/CustomerNotificationCreate"));
const CustomerNotificationsList = React.lazy(() => import("./CustomerNotification/CustomerNotificationsList"));
const CustomerRepeatersList = React.lazy(() => import("./CustomerRepeater/CustomerRepeatersList"));
const CustomerRepeaterView = React.lazy(() => import("./CustomerRepeater/CustomerRepeaterView"));
const CustomerReportersList = React.lazy(() => import("./CustomerReporter/CustomerReportersList"));
const CustomerReporterView = React.lazy(() => import("./CustomerReporter/CustomerReporterView"));

export const customerConfigs = [
  {
    auth: ['user'],
    path: "/customer/dashboard",
    element: <CustomerDashboard />,
  },
  {
    auth: ['user'],
    path: "/customer/message/list",
    element: <CustomerMessagesList />,
  },
  {
    auth: ['user'],
    path: "/customer/message/list/repeater/:byRepeater",
    element: <CustomerMessagesList />,
  },
  {
    auth: ['user'],
    path: "/customer/message/list/reporter/:byReporter",
    element: <CustomerMessagesList />,
  },
  {
    auth: ['user'],
    path: "/customer/notification/list",
    element: <CustomerNotificationsList />,
  },
  {
    auth: ['user'],
    path: "/customer/notification/create",
    element: <CustomerNotificationCreate />,
  },
  {
    auth: ['user'],
    path: "/customer/repeater/list",
    element: <CustomerRepeatersList />,
  },
  {
    auth: ['user'],
    path: "/customer/repeater/:id",
    element: <CustomerRepeaterView />,
  },
  {
    auth: ['user'],
    path: "/customer/reporter/list",
    element: <CustomerReportersList />,
  },
  {
    auth: ['user'],
    path: "/customer/reporter/list/repeater/:byRepeater",
    element: <CustomerReportersList />,
  },
  {
    auth: ['user'],
    path: "/customer/reporter/:id",
    element: <CustomerReporterView />,
  },
];
