import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AppLngSwitcher from "@crema/core/AppLngSwitcher";
import Box from "@mui/material/Box";
import AppSearchBar from "@crema/core/AppSearchBar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import AppMessages from "../../../AppMessages";
import AppNotifications from "../../../AppNotifications";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppLogo from "../../components/AppLogo";
import UserInfo from "../../components/UserInfo";
import appStore from "store/app.store";
import AppTooltip from "@crema/core/AppTooltip";
import { useNavigate } from "react-router-dom";
import { alpha } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { Home } from "@mui/icons-material";
import { useIntl } from "react-intl";

interface Props {
  isAdmin: boolean;
}

const AppHeader: React.FC<Props> = ({ isAdmin }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate()
  const {messages} = useIntl()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const _goHome = () => {
    navigate('/home')
  };

  return (
    <AppBar
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.paper",
        transition: "width 0.5s ease",
        width: "100%",
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 56, sm: 70 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        {!isAdmin ? <></> : <Hidden lgUp>
          <IconButton
            sx={{
              color: "text.secondary",
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() =>dispatch(appStore.actions.toggleNavCollapse())}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>}
        <Box
          sx={{
            "& .logo-text": {
              display: { xs: "none", sm: "block" },
            },
          }}
        >
          <AppLogo />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        <Box
          sx={{
            minHeight: 40,
            position: "relative",
            display: { xs: "none", sm: "block" },
            "& .searchRoot": {
              position: { xs: "absolute", sm: "relative" },
              right: { xs: 0, sm: "auto" },
              top: { xs: 0, sm: "auto" },
            },
          }}
        >
        </Box>

        <Box sx={{ ml: 4 }}>
        <AppTooltip title={messages['sidebar.admin.dashboard']} placement={'bottom'}>
          <IconButton
            sx={{
              height: 40,
              width: 40,
              borderRadius: "50%",
              padding: "6px 9px",
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: "transparent",
              "&:hover, &:focus": {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
              "& .langText": {
                ml: 2.5,
                fontSize: 16,
                fontWeight: Fonts.REGULAR,
                display: { xs: "none", sm: "inline-block" },
              },
              "& svg": {
                fontSize: 20,
              },
            }}
            className="lang-switcher-btn"
            aria-label="account of current user"
            aria-controls="language-switcher"
            aria-haspopup="true"
            onClick={_goHome}
            color="inherit"
            size="large"
          >
            <Home />
          </IconButton>
        </AppTooltip>        
        </Box>

        <Box sx={{ ml: 4 }}>
          <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
        </Box>


        <Box
          sx={{
            ml: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Hidden smDown>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: -2,
                marginRight: -2,
              }}
            >
            </Box>
          </Hidden>

          <Box
            sx={{
              ml: { sm: 4 },
              mr: { xs: 4, sm: 0 },
              minWidth: { md: 220 },
              "& .user-info-view": {
                p: 0,
              },
              "& .user-info": {
                display: { xs: "none", md: "block" },
              },
            }}
          >
            <UserInfo />
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <AppNotifications isMenu />
            </MenuItem>
            <MenuItem>
              <AppMessages isMenu />
            </MenuItem>
            <MenuItem>Setting</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
