import { authRouteConfig } from "./auth";
import { initialCustomerUrl, initialUrl } from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import React from "react";
import { errorPagesConfigs } from "./errorPages";
import { samplePagesConfigs } from "./sample";
import { profilePage } from "./profile";
import { accountsConfig } from "./account";
import { companysConfig } from "./company";
import { locationsConfig } from "./location";
import { systemmodelsConfig } from "./systemmodel";
import { repeatermodelsConfig } from "./repeatermodel";
import { reportermodelsConfig } from "./reportermodel";
import { systemsConfig } from "./system";
import { repeatersConfig } from "./repeater";
import { reportersConfig } from "./reporter";
import { connectorsConfig } from "./connector";
import { messagesConfig } from "./message";
import { notificationsConfig } from "./notification";
import { dashboardConfigs } from "./dashboard";
import { customerConfigs } from "./customer";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...samplePagesConfigs, 
    ...profilePage, 
    ...accountsConfig, 
    ...companysConfig,
    ...locationsConfig,
    ...systemmodelsConfig,
    ...repeatermodelsConfig,
    ...reportermodelsConfig,
    ...systemsConfig,
    ...repeatersConfig,
    ...reportersConfig,
    ...connectorsConfig,
    ...messagesConfig,
    ...notificationsConfig,
    ...dashboardConfigs,
    ...customerConfigs
    ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};


const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
