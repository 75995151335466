import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const CompanyList = React.lazy(() => import('./CompanyList'));
const CompanyView = React.lazy(() => import('./CompanyView'));
const CompanyCreate = React.lazy(() => import('./CompanyCreate'));


export const companysConfig = [
  {
    auth: ['admin'],
        path: '/company/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <CompanyList />
  },
  {
    auth: ['admin'],
        path: '/company/:id',
        permittedRole: RoutePermittedRole.Admin, 
    element: <CompanyView />
  },
  {
    auth: ['admin'],
        path: '/company/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <CompanyCreate />
  }
];
