import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const SystemModelList = React.lazy(() => import('./SystemModelList'));
const SystemModelCreate = React.lazy(() => import('./SystemModelCreate'));



export const systemmodelsConfig = [
 {
    auth: ['admin'],
        path: '/systemmodel/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemModelList />
  },
  {
    auth: ['admin'],
        path: '/systemmodel/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemModelCreate />
  },
];
