import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const AccountsList = React.lazy(() => import('./AccountList'));
const AccountView = React.lazy(() => import('./AccountView'));
const AccountCreate = React.lazy(() => import('./AccountCreate'));

export const accountsConfig = [
  {
    auth: ['admin'],
        path: '/account/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <AccountsList />
  },
  {
    auth: ['admin'],
        path: '/account/list/company/:byCompany',
        permittedRole: RoutePermittedRole.Admin, 
    element: <AccountsList />
  },
  {
    auth: ['admin'],
        path: '/account/:id',
        permittedRole: RoutePermittedRole.Admin, 
    element: <AccountView />
  },
  {
    auth: ['admin'],
        path: '/account/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <AccountCreate />
  },
];
