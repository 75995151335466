
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";
import { ObjCompanyBase, ObjCompanyEdit, ObjSystemBase } from "./types/ApiTypes";

export class Company {


    /**
     * Company of User
     * @param companyId
     * @return Promise<boolean>
     */
    static company(companyId: any, isCustomer?:boolean): Promise<ObjCompanyEdit | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const url = isCustomer ? '/customer/company/':'/admin/company/';
            const response = await Api.get(url + companyId, undefined, true);


                if (response && response.company) {
                        //Parse JSON String "company"
                        let responseObj = response.company;
                        LogHelper.logInfo('Services/Api/Company/company', 'Load company success');
                        resolve(responseObj);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Company/company', 'Load company failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Company/company', 'Load company failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    /**
     * Company of User
     * @param companyId
     * @return Promise<boolean>
     */
    static companyDelete(companyId: number): Promise<ObjCompanyEdit | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.delete('/admin/company/' + companyId, undefined, true);


                if (response && response.company) {
                        //Parse JSON String "company"
                        let responseObj = response.company;
                        LogHelper.logInfo('Services/Api/Company/companyDelete', 'Delete company success');
                        resolve(responseObj);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/PCompany/companyDelete', 'Delete company failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Company/companyDelete', 'Delete company failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    /**
     * Company of User
     * @param companyId
     * @return Promise<boolean>
     */
    static companyUpdate(companyId: string|number, data:any): Promise<ObjCompanyEdit | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/admin/company/' + companyId, data, true);


                if (response && response.company) {
                        //Parse JSON String "company"
                        let responseObj = response.company;
                        LogHelper.logInfo('Services/Api/Company/companyCreate', 'create company success');
                        resolve(responseObj);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/PCompany/companyCreate', 'create company failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Company/companyCreate', 'create company failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    /**
     * Company of User
     * @param companyId
     * @return Promise<boolean>
     */
    static companyCreate(data:ObjCompanyEdit): Promise<ObjCompanyEdit | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/admin/company', data, true);

                if (response && response.company) {
                        //Parse JSON String "company"
                        let responseObj = response.company;
                        LogHelper.logInfo('Services/Api/Company/companyCreate', 'create company success');
                        resolve(responseObj);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/PCompany/companyCreate', 'create company failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Company/companyCreate', 'create company failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    
    /**
     * Companys
     * @param roundId
     * @return Promise<boolean>
     */
     static companys(skip: number, search?:string, sort?:string, desc?:boolean, count?:boolean): Promise<ObjCompanyEdit | false> {
         
        let qu = [];
        if(sort) qu.push('sort='+sort)
        if(desc) qu.push('desc=true')
        if(count) qu.push('count=true')
        if(skip) qu.push('skip='+skip)

        let query = '?' + qu.join('&');
        
        return new Promise(async (resolve: any, reject:any) => {

            try {

                const response = search ? await Api.get('/admin/company/search/' + search + query, undefined, true) : await Api.get('/admin/company/all' + query, undefined, true);


                if (response && response['companys']) {
                        //Parse JSON String "company"
                        LogHelper.logInfo('Services/Api/Company/companys', 'Load companys success');
                        resolve(response['companys']);
                        return;
                }

                //Login companys
                LogHelper.logError('Services/Api/Company/companys', 'Load companys failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Company/companys', 'Load companys failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    
    /**
     * Company of User
     * @param companyId
     * @return Promise<boolean>
     */
     static companyAndSystems(companyId: any, isCustomer?:boolean): Promise<ObjCompanyBase | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const url = isCustomer ? '/customer/company/':'/admin/company/';
            const response = await Api.get(url + companyId + '/systems', undefined, true);


                if (response && response.company) {
                        //Parse JSON String "company"
                        let responseObj = response.company;
                        LogHelper.logInfo('Services/Api/Company/companyAndSystems', 'Load companyAndSystems success');
                        resolve(responseObj);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Company/companyAndSystems', 'Load companyAndSystems failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Company/companyAndSystems', 'Load companyAndSystems failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
}
