
export default {
    api: {
        apiUrl: process.env.NODE_ENV === "production" ? 'https://api.alarm-manager.de/v1' : process.env.NODE_ENV === "test" ? 'https://test-fam-api-kube.jeffsoft.de/v1' : 'https://fam-api-dev.jeffsoft.de/v1',
        apiKey1: process.env.NODE_ENV === "production" ? 'UidL1AUqoMMEAW7' : 'SYtrG3BUtg8d',
        apiKey2: process.env.NODE_ENV === "production" ? '7ZrVIEqFdWVYaqZ' : 'QcnlkCXJJeBe',
        timeout: 5000,
    },
    debug: 0,
    version: 'v0.2.2',
    buildTxt: '--build_text--',

};
