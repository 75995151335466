import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const RepeatersList = React.lazy(() => import('./RepeaterList'));
const RepeaterView = React.lazy(() => import('./RepeaterView'));
const RepeaterCreate = React.lazy(() => import('./RepeaterCreate'));



export const repeatersConfig = [
 {
    auth: ['admin'],
        path: '/repeater/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeatersList />
  },
 {
    auth: ['admin'],
        path: '/repeater/:id',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeaterView />
  },
 {
    auth: ['admin'],
        path: '/repeater/list/system/:bySystem',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeatersList />
  },
  {
    auth: ['admin'],
        path: '/repeater/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeaterCreate />
  },
];
