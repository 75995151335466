import Lang from "./Lang"


export type ErrorObj = {
    code: number,
    message: string,
    userMessage: string,
}

export const Errors = {

    //APP_ERRORS 0000-0999


    //SERVER
    SERVER__CONNECTION_FAILED:                              {code: 1000, message: 'server connection failed', userMessage:(Lang.ERR_IS_OCCURRED)},
    SERVER__SESSION_EXPIRED_BY_FORCE_LOGIN:                 {code: 1001, message: 'session expired by force login', userMessage:(Lang.ERR_IS_OCCURRED)},
    SERVER__SESSION_CLOSED_BY_NO_FORCE_LOGIN:               {code: 1002, message: 'session expired by no force login', userMessage:(Lang.ERR_IS_OCCURRED)},
    SERVER__SESSION_CLOSED_BY_TRY_JOIN_FAILED:              {code: 1003, message: 'session expired by try join failed', userMessage:(Lang.ERR_IS_OCCURRED)},
    SERVER__SESSION_CLOSED_BY_TRY_JOIN_GAME_FAILED:         {code: 1003, message: 'session expired by try join game failed', userMessage:(Lang.ERR_IS_OCCURRED)},
    SERVER__SESSION_CLOSED_BY_KICKED:                       {code: 1005, message: 'session expired by kicked from roomleader', userMessage:(Lang.ERR_IS_OCCURRED)},
    SERVER__SERVER_NOT_AVAILABLE:                           {code: 1005, message: 'server not available - offline or no internet', userMessage:(Lang.ERR_IS_OCCURRED)},

    //GENERAL
    GENERAL__ERROR:                                         {code: 1500, message: 'general error', userMessage:(Lang.ERR_IS_OCCURRED)},
    GENERAL__REQUIRED_INPUT:                                {code: 1509, message: 'required input not available', userMessage:(Lang.ERR_REQUIRED_INPUTS_MISSING)},
    GENERAL__PASSWORD_NOT_EQUAL:                            {code: 1510, message: 'passwords are not equal', userMessage:(Lang.ERR_PASSWORDS_NOT_EQUAL)},
    GENERAL__EMAIL_NOT_EQUAL:                               {code: 1511, message: 'emails are not equal', userMessage:(Lang.ERR_EMAILS_NOT_EQUAL)},
    GENERAL__EMAIL_IS_NOT_VALID:                            {code: 1512, message: 'emails is not valid', userMessage:(Lang.ERR_EMAIL_NOT_VALID)},
    GENERAL__INPUT_HAS_NOT_VALID_CHARS:                     {code: 1513, message: 'input is not valid', userMessage:(Lang.ERR_INPUT_HAS_NOT_VALID_CHARS)},
    GENERAL__USERNAME_BLOCKED_CHARS:                        {code: 1514, message: 'username has blocked characters', userMessage:(Lang.ERR_USER_HAS_BLOCKED_CHARS)},
    GENERAL__MISSING_CHECKED_TERMS:                         {code: 1515, message: 'terms need to check it', userMessage:(Lang.ERR_USER_NEED_TERMS_CHECKED)},
    GENERAL__SENDING_FEEDBACK_FAILED:                       {code: 1516, message: 'send feedback failed', userMessage:(Lang.ERR_PREPARE_SEND_FEEDBACK_FAILED)},

    //API
    API__INIT_API_CONNECTION_FAILED_BY_EXC:                 {code: 4000, message: 'init api connection failed by exception', userMessage:(Lang.ERR_SERVER__CONNECTION_FAILED)},
    API__INIT_API_CONNECTION_FAILED:                        {code: 4001, message: 'init api connection failed', userMessage:(Lang.ERR_SERVER__CONNECTION_FAILED)},
    API_GETACCESS__FAILED_NO_TOKEN:                         {code: 4002, message: 'getaccess to api failed - no token or response', userMessage:(Lang.ERR_SERVER__CONNECTION_FAILED)},
    API_GETACCESS__FAILED_BY_TIMEOUT:                       {code: 4003, message: 'getaccess to api failed - no token or response', userMessage:(Lang.ERR_API__TIMEOUT)},
    API_GETACCESS__FAILED_BY_EXC:                           {code: 4004, message: 'getaccess to api failed - no token or response', userMessage:(Lang.ERR_SERVER__CONNECTION_FAILED)},
    API__TIMEOUT_ERROR:                                     {code: 4005, message: 'api timeout error', userMessage:(Lang.ERR_API__TIMEOUT)},

    API_GET__FAILED_BY_EXC:                                 {code: 4100, message: 'get-request on api failed by exception', userMessage:(Lang.ERR_API__REQUEST_FAILED)},
    API_POST__FAILED_BY_EXC:                                {code: 4101, message: 'post-request to api failed by exception', userMessage:(Lang.ERR_API__REQUEST_FAILED)},
    API_POST__FAILED:                                       {code: 4102, message: 'post-request to api failed', userMessage:(Lang.ERR_API__REQUEST_FAILED)},
    API__REQUEST_FAILED_BY_EXC:                             {code: 4103, message: 'request failed by exception', userMessage:(Lang.ERR_API__REQUEST_FAILED)},

    API_AUTH__LOGIN_FAILED:                                 {code: 4200, message: 'login failed - by api', userMessage:(Lang.ERR_IS_OCCURRED)},
    API_AUTH__LOGIN_FAILED_BY_EXC:                          {code: 4201, message: 'login failed - by api exception', userMessage:(Lang.ERR_IS_OCCURRED)},
    API_AUTH__CHECKLOGIN_FAILED:                            {code: 4202, message: 'checkLogin failed - by api', userMessage:(Lang.ERR_IS_OCCURRED)},
    API_AUTH__CHECKLOGIN_FAILED_BY_EXC:                     {code: 4203, message: 'checkLogin failed - by api exception', userMessage:(Lang.ERR_IS_OCCURRED)},
    API_AUTH__LOGOUT_FAILED:                                {code: 4204, message: 'logout failed - by api', userMessage:(Lang.ERR_AUTH__LOGOUT_FAILED)},
    API_AUTH__LOGOUT_FAILED_BY_EXC:                         {code: 4205, message: 'logout failed - by api exception', userMessage:(Lang.ERR_AUTH__LOGOUT_FAILED)},
    API_AUTH__LOGIN_NOT_SAVED:                              {code: 4206, message: 'login cannot save on storage', userMessage:(Lang.ERR_AUTH__LOGIN_NOT_SAVED)},

    API_AUTH__REGISTER_FAILED:                              {code: 4207, message: 'register failed', userMessage:(Lang.ERR_AUTH__REGISTER_FAILED)},
    API_AUTH__REGISTER_FAILED_BY_EXC:                       {code: 4208, message: 'register failed by api exception', userMessage:(Lang.ERR_AUTH__REGISTER_FAILED_BY_EXC)},
    API_AUTH__REGISTER_FAILED_ALREADY_USED_USER_OR_EMAIL:   {code: 4209, message: 'register failed username or email already used', userMessage:(Lang.ERR_AUTH__REGISTER_FAILED_USED_USERNAME)},
    API_AUTH__REGISTER_FAILED_BLOCKED:                      {code: 4210, message: 'register failed blocked data', userMessage:(Lang.ERR_AUTH__REGISTER_FAILED_BLOCKED)},

    API_AUTH__REQUEST_FORGOT_FAILED:                        {code: 4211, message: 'forgot password failed', userMessage:(Lang.ERR_AUTH__REQUEST_FORGOT_FAILED)},
    API_AUTH__SET_FORGOT_FAILED:                            {code: 4212, message: 'set forgot password failed', userMessage:(Lang.ERR_AUTH__SET_FORGOT_FAILED)},

    API_REFRESHTOKEN__FAILED_NO_STOREAGE_DATA:              {code: 4213, message: 'refreshtoken failed no storage data', userMessage:(Lang.ERR_AUTH__REFRESH_TOKEN_FAILED_NO_DATA)},
    API_REFRESHTOKEN__FAILED:                               {code: 4214, message: 'refreshtoken failed', userMessage:(Lang.ERR_AUTH__REFRESH_TOKEN_FAILED)},
    API_REFRESHTOKEN__FAILED_BY_EXC:                        {code: 4215, message: 'refreshtoken failed by exc', userMessage:(Lang.ERR_AUTH__REFRESH_TOKEN_FAILED)},
    API_REFRESHTOKEN__FAILED_INVALID:                        {code: 4216, message: 'refreshtoken failed session invalid', userMessage:(Lang.ERR_AUTH__REFRESH_TOKEN_FAILED)},
    
    API_PLAYER__LOAD_PLAYER_FAILED:                         {code: 4300, message: 'load player failed', userMessage:(Lang.ERR_IS_OCCURRED)},
    API_PLAYER__LOAD_PLAYER_FAILED_BY_EXC:                  {code: 4301, message: 'load player failed by exception', userMessage:(Lang.ERR_IS_OCCURRED)},
    API__LOAD_GAMES_FAILED:                                  {code: 4302, message: 'load games failed by exception', userMessage:(Lang.ERR_IS_OCCURRED)},
    API__LOAD_FAILED:                                        {code: 4310, message: 'load failed by exception', userMessage:(Lang.ERR_IS_OCCURRED)},

    //CHAT
    CHAT__SEND_MESSAGE_FAILED:                              {code: 5100, message: 'send chat message failed', userMessage:(Lang.ERR_IS_OCCURRED)},


}
