import { useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import AuthRoutes from "@crema/utility/AuthRoutes";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppLayout from "@crema/core/AppLayout";
import { BrowserRouter } from "react-router-dom";
import JWTAuthAuthProvider from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import { RootState } from "store/store";
import ToastMsg from "shared/components/core/ToastMsg";




const AppInit = () => {

  const storeAPIConnected = useSelector((state: RootState) => state.session.apiConnected);
  if(!storeAPIConnected) {
    return <></>
  }

  return (
      <AppThemeProvider>
        <AppStyleProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <JWTAuthAuthProvider>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                  <ToastMsg />
                </AuthRoutes>
              </JWTAuthAuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppStyleProvider>
      </AppThemeProvider>
  )
};

export default AppInit;
