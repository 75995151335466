import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const SystemsList = React.lazy(() => import('./SystemList'));
const SystemCreate = React.lazy(() => import('./SystemCreate'));
const ReporterCreate = React.lazy(() => import('./../reporter/ReporterCreate'));
const RepeaterCreate = React.lazy(() => import('./../repeater/RepeaterCreate'));
const SystemView = React.lazy(() => import('./SystemView'));
const SystemServiceDevices = React.lazy(() => import('./SystemServiceDevices'));



export const systemsConfig = [
 {
    auth: ['admin'],
        path: '/system/list',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemsList />
  },
 {
    auth: ['admin'],
        path: '/system/list/company/:byCompany',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemsList />
  },
 {
    auth: ['admin'],
        path: '/system/list/location/:byLocation',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemsList />
  },
 {
    auth: ['admin'],
        path: '/system/:id',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemView />
  },
  {
    auth: ['admin'],
        path: '/system/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <SystemCreate />
  },
  {
    auth: ['admin'],
        path: '/system/:id/repeater/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <RepeaterCreate />
  },
  {
    auth: ['admin'],
        path: '/system/:id/reporter/create',
        permittedRole: RoutePermittedRole.Admin, 
    element: <ReporterCreate />
  },
  {
    auth: ['admin'],
        path: '/system/:id/servicedevices',
        permittedRole: RoutePermittedRole.Admin, 
        element: <SystemServiceDevices />
  },
];
