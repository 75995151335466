import React from "react";
import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";
import { alpha, Box, Typography } from "@mui/material";
import Config from "config/Config";
import { useNavigate } from "react-router-dom";

interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  const navigate = useNavigate();

  const _goHome = () => {
    navigate('/')
  }

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        width: { xs: 110, sm: 210 },
        padding: 2.5,
        marginLeft: 1,
        paddingBottom: 0,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "flex-end",
        justifyContent: "center"
      }}
      onClick={_goHome}
      className="app-logo"
    >
      <Box display={{xs: 'flex', sm: 'none'}}>
      <img src="/assets/images/logo-white.png" height="100%" width={'auto'} />
      </Box>
      <Box display={{xs: 'none', sm: 'flex'}}>
      <img src="/assets/images/logo-white-with-name.png" height="100%" width={'auto'} />
      </Box>
      <Typography color="secondary" mb={1}>{Config.version}</Typography>
    </Box>
  );
};

export default AppLogo;
